import { Swiper } from 'swiper'
import { EffectFade } from 'swiper/modules'

import { BasicSwiper } from '@app-components'
import { swiperFactoryInjector } from '@app-injections'
import { viewportRanges } from '@app-bootstrap/viewport-tracking.bootstrap'

export class FrontPageGrid {
    private frontPageBlocks: NodeListOf<HTMLElement>

    constructor() {
        this.frontPageBlocks = document.querySelectorAll('[data-homepage-grid-swiper]')
        this.initialize()
    }

    private initialize(): void {
        this.frontPageBlocks.forEach(block => {
            this.setupVideos(block)
            const swiperOptions = JSON.parse(block.getAttribute('data-swiper-options') || '{}')
            const swiperInstance = swiperFactoryInjector.createInstance(block, swiperOptions, [EffectFade])
            this.handleSlideChange(swiperInstance)
        })
    }

    private setupVideos(block: HTMLElement): void {
        block.querySelectorAll<HTMLElement>('.slide').forEach((slide) => this.setVideoSource(slide))
    }

    /**
     * Sets the appropriate video source for the slide based on the viewport size.
     * @param slide - The slide element containing the video.
     */
    private setVideoSource(slide: HTMLElement): void {
        const video = slide.querySelector<HTMLVideoElement>('video') as HTMLVideoElement
        if (! video) return

        const isMobile = window.innerWidth <= viewportRanges.small.max
        const sourceAttr = isMobile ? 'data-video-src-mobile' : 'data-video-src'
        const videoUrl = video.getAttribute(sourceAttr)

        if (videoUrl) {
            this.loadVideo(video, videoUrl, slide)
        } else if (this.shouldLoadMobileFallback(video, isMobile)) {
            const fallbackVideoUrl = video.getAttribute('data-video-src')!
            this.loadVideo(video, fallbackVideoUrl, slide)
        } else {
            this.disableVideo(slide, video)
        }
    }

    private handleSlideChange(swiperInstance: BasicSwiper): void {
        this.waitForVideoToEnd(swiperInstance.swiper, 0)
        swiperInstance.swiper.on('slideChange', () => {
            this.waitForVideoToEnd(swiperInstance.swiper, swiperInstance.swiper.activeIndex)
        })
    }

    private shouldLoadMobileFallback(video: HTMLVideoElement, isMobile: boolean): boolean {
        return isMobile &&
            ! video.getAttribute('data-has-image-mobile') &&
            video.getAttribute('data-video-src') !== null
    }

    private loadVideo(video: HTMLVideoElement, videoUrl: string, slide: HTMLElement): void {
        video.setAttribute('src', videoUrl)
        slide.querySelector('picture')?.style.setProperty('display', 'none')
    }

    private disableVideo(slide: HTMLElement, video: HTMLVideoElement): void {
        video.classList.add('disabled')
        video.style.setProperty('display', 'none')
        const bannerVideoContent = slide.querySelector<HTMLElement>('.banner-video__content')
        bannerVideoContent?.style.setProperty('display', 'none')
    }

    /**
     * Waits for the video to end on the current slide before starting autoplay.
     * @param swiper - The Swiper instance.
     * @param currentSlide - The index of the current slide.
     */
    private waitForVideoToEnd(swiper: Swiper, currentSlide: number): void {
        const slides = swiper.slides

        if (slides.length < 2) {
            return
        }

        const video = slides[currentSlide].querySelector<HTMLVideoElement>('video:not(.disabled)')

        if (! video) {
            return
        }

        video.loop = false
        swiper.autoplay.stop()

        video.onended = () => swiper.autoplay.start()
        video.play()
    }
}
