import { addClasses, queryAll, readDataset, removeClasses } from 'lambda-dom'
import EventDelegation from '@jjwesterkamp/event-delegation'

import { swiperFactoryInjector } from '@app-injections'
import { FrontPageGrid } from './FrontPageGrid'

// ------------------------------------------------------------------------------
// TopLists TabBar -- which triggers the tiny slider on mobile
// ------------------------------------------------------------------------------
const topListsSelector = 'ul#tabs--header'
const topListHeader = document.querySelector(topListsSelector)

if (topListHeader) {
    EventDelegation
        .within(topListsSelector)
        .events('click')
        .select<HTMLAnchorElement>('li')
        .listen((event) => {
            const delegator = event.delegator

            if (delegator && ! delegator.parentElement?.classList.contains('is-active')) {
                const selection = readDataset('tabId')(delegator)
                if (selection) {
                    queryAll('[data-tab-id]').forEach((element) => removeClasses('is-active')(element))
                    queryAll(`[data-tab-id="${selection}"]`).forEach((element) => addClasses('is-active')(element))

                    if (! delegator.classList.contains('initiated')) {
                        swiperFactoryInjector.initialize(`#top-list-carousel-${selection} .swiper-container`)
                        delegator.classList.add('initiated')
                    }
                }
            }
        })

    swiperFactoryInjector.initialize('#top-list-carousel-0 .swiper-container')
}

// Initialize FrontPageGrid
new FrontPageGrid()


